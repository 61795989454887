import { allEnv } from 'next-runtime-env';
import { z } from 'zod';

export const envSchema = z.object({
  NEXT_PUBLIC_BASE_URL: z.string(),
  NEXT_PUBLIC_API_BASE: z.string(),
  NEXT_PUBLIC_API_BASE_V3: z.string(),
  NEXT_PUBLIC_AUTH_BASE: z.string(),
  NEXT_PUBLIC_AUTH_AUDIENCE: z.string(),
  NEXT_PUBLIC_CLIENT_ID: z.string(),
  NEXT_PUBLIC_ENV: z.string(),
  NEXT_PUBLIC_MIXPANEL_TOKEN: z.string(),
  NEXT_PUBLIC_AUTH0_CALLBACK: z.string(),
  NEXT_PUBLIC_UNLEASH_URL: z.string(),
  NEXT_PUBLIC_UNLEASH_CLIENT_KEY: z.string(),
  NEXT_PUBLIC_MAGICBELL_API_KEY: z.string(),
  NEXT_PUBLIC_APP_NAME: z.string(),
  NEXT_PUBLIC_VERSION: z.string(),
  NEXT_PUBLIC_REASON_DEFAULT_STRING: z.string(),
  NEXT_PUBLIC_MARKERIO_PROJECT: z.string(),
  NEXT_PUBLIC_DD_APP_ID: z.string(),
  NEXT_PUBLIC_DD_TOKEN: z.string(),
  NEXT_PUBLIC_DD_SERVICE: z.string().optional(),
  NEXT_PUBLIC_DD_SERVICE_NAME: z.string(),
});

type Env = z.infer<typeof envSchema>;

let envParsed: Env | undefined = undefined;

export const env = <T extends keyof Env>(key: T): Env[T] => {
  if (envParsed === undefined) {
    envParsed = envSchema.parse(allEnv());
  }
  return envParsed[key];
};
