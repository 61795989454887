import { TimeBreakdown } from './types';

export const padLeadingZero = (num: number): string =>
  num.toString().padStart(2, '0');

export const getTimeBreakdown = (totalSeconds: number): TimeBreakdown => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return { hours, minutes, seconds };
};

export const getTotalSeconds = ({
  hours,
  minutes,
  seconds,
}: TimeBreakdown): number =>
  (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);

export const isTimeBreakdown = (value: unknown): value is TimeBreakdown => {
  if (typeof value !== 'object' || value === null) return false;
  const requiredKeys = ['hours', 'minutes', 'seconds'];
  return requiredKeys.every((key) => key in value);
};
