import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { normalizeRouterQuery } from '../formatting';

export const useTabState = <T extends string>(
  defaultTab: T,
  validTabs: T[]
) => {
  const router = useRouter();
  const { query } = router;

  const isValidTab = (value: unknown): value is T =>
    typeof value === 'string' && validTabs.includes(value as T);

  const getInitialTab = (): T => {
    const normalizedQuery = normalizeRouterQuery(query);
    const tabFromQuery = normalizedQuery.tab;
    return isValidTab(tabFromQuery) ? tabFromQuery : defaultTab;
  };

  const [tab, setTab] = useState<T>(getInitialTab);

  useEffect(() => {
    if (tab !== query.tab) {
      router.replace(
        {
          pathname: router.pathname,
          query: { ...query, tab },
        },
        undefined,
        { shallow: true }
      );
    }
  }, [tab, query, router]);

  return [tab, setTab] as const;
};
