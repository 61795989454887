import Stack from '@mui/material/Stack';
import { RouteKey } from 'GlobalRoutes';
import { useFlag } from '@automata/utils/hooks';
import { MainLink } from './MainLink';

export type MainLinkProps = {
  label: string;
  page: RouteKey;
  show?: boolean;
};

export const MainLinks = (): JSX.Element => {
  const isWorkcellsEnabled = useFlag('fe.cue_workcell');
  const isCanvasAppEnabled = useFlag('fe.canvas-builder');

  const menuItems: MainLinkProps[] = [
    {
      label: 'Canvas Workflows',
      page: 'canvasWorkflow',
      show: isCanvasAppEnabled,
    },
    {
      label: 'Workflows',
      page: 'workflow',
    },
    {
      label: 'Hubs',
      page: 'operator',
      show: !isWorkcellsEnabled,
    },
    {
      label: 'Workcells',
      page: 'workcells',
      show: isWorkcellsEnabled,
    },
  ];

  return (
    <Stack direction="row" alignItems="center" ml={3} gap={2}>
      {menuItems.map(
        ({ label, page, show = true }) =>
          show && <MainLink key={page} label={label} page={page} />
      )}
    </Stack>
  );
};
