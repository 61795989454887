import { NextRouter, Router } from 'next/router';

/*
 * these are split into services as the
 * testing spies dont seem to work with them in the
 * same module as useConfirmNavigateAway
 */

export const beforeUnloadHandler = (
  e: BeforeUnloadEvent,
  confirmationMessage: string
) => {
  e.preventDefault();
  e.returnValue = confirmationMessage;
  return confirmationMessage;
};

export const routeChangeHandler = (
  confirmationMessage: string,
  router: NextRouter,
  routerClass: typeof Router
) => {
  const didUserConfirm = confirm(confirmationMessage);

  if (!didUserConfirm) {
    routerClass.events.emit('routeChangeError');
    window.history.pushState(null, '', router.asPath);

    // Throw string literal rather than an Error to prevent the route change,
    // but stop Next displaying an error to the user
    throw 'Navigation aborted';
  }
};
