import { TrackedButton } from '@automata/ui';
import NextLink from 'next/link';
import MuiLink from '@mui/material/Link';
import { useRoute } from 'hooks/useRoute';
import { MainLinkProps } from './index';

export const MainLink = ({
  label,
  page,
}: MainLinkProps): JSX.Element | null => {
  const route = useRoute(page);

  return (
    <MuiLink href={route} component={NextLink}>
      <TrackedButton
        trackLabel={`AppBar.mainNav.navigate${page}`}
        color="neutral"
        variant="text"
      >
        {label}
      </TrackedButton>
    </MuiLink>
  );
};
